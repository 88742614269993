















































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { userService } from '@/services';
import ResponseCommon from '@/commons/ResponseCommon';

@Component({
  components: {
  },
})
export default class LoginPage extends Mixins(CommonMixin) {
  private password: string = '';
  private usernameOrEmail: string = '';
  private isAlertError: boolean = false;

  private login() {
    this.isAlertError = false;
    this.$store.dispatch('showLoading');
    userService.logIn(this.usernameOrEmail, this.password).then((res: any) => {
      this.axios.prototype.setUserInfo(res.data.token);
      userService.getProfile().then((res2: any) => {
        this.$store.dispatch('hiddenLoading');
        if (res2.data.code === ResponseCommon.CODE_SUCCESS) {
          this.$store.dispatch('setUser', res2.data.data);
          if (this.$route.path === '/login') {
            this.$router.push({ path: '/' });
          } else {
            this.$router.go(-1);
          }
        }
      })
    })
    .catch((err: any) => {
      this.$store.dispatch('hiddenLoading');
      this.isAlertError = true;
      this.axios.prototype.deleteUserInfo();
    });
  }

  private mounted() {
    this.$nextTick(() => {
      const linkLogin = document.getElementById('linkForgot');
      if (linkLogin) {
        linkLogin.addEventListener('click', this.toPageForgot);
      }
    });
  }

  private toPageForgot(event: Event) {
    event.preventDefault();
    this.$router.push({ path: '/forgot-password' });
  }
}
