





















import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { userService } from '@/services';

@Component({
  components: {
  },
})
export default class VerifyPage extends Mixins(CommonMixin) {
  private token: string = '';
  private isSuccess: boolean = false;
  private isLoadData: boolean = true;

  private mounted() {
    this.$nextTick(() => {
      const linkLogin = document.getElementById('linkLogin');
      if (linkLogin) {
        linkLogin.addEventListener('click', this.toPageLogin);
      }
    });
  }

  private async created() {
    this.$store.dispatch('showLoading');
    if (this.$route.params.token) {
      this.token = this.$route.params.token;
      await this.verify();
    }
    this.$store.dispatch('hiddenLoading');
  }

  private async verify() {
    await userService.verify({ token: this.token }).then((res: any) => {
      if(
        res &&
        res.data &&
        res.data.code === 0
      ) {
        this.isSuccess = true;
      }
    })
    .catch((err: any) => {
      this.isSuccess = false;
    }).finally(() => this.isLoadData = true)
  }

  private toPageLogin(event: Event) {
    event.preventDefault();
    this.$router.push({ path: '/user/login' });
  }
}
