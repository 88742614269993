





























































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { BetHistory, MatchView, User } from '@/models';
import Enums from '@/enums/enums';
import { dateUtils } from '@/utils/DateUtils';
import BetHistoryEnum from '@/enums/BetHistoryEnum';
import { betHistoryService } from '@/services';
import HttpStatus from '@/commons/HttpStatus';
import ResponseCommon from '@/commons/ResponseCommon';

@Component({
  components: {
  },
})
export default class ModalMatch extends Mixins(CommonMixin) {
  private get Enums() {
    return Enums;
  }
  private get TeamWin() {
    return BetHistoryEnum.TeamWin;
  }
  private get language() {
    return this.$store.state.storedLang;
  }
  private get showModal() {
    return (this.$store.state.showModalMatch && (this.$store.state.user.id ? true : false));
  }
  private set showModal(value: any) {
    this.$store.dispatch('setMatch', new MatchView());
    this.$store.dispatch('setModalMatch', value);
  }
  get user(): User {
    return this.$store.state.user;
  }
  private get match(): MatchView {
    return this.$store.state.match;
  }
  private get DateUtils() {
    return dateUtils;
  }
  private intervalid = 0;
  private isHolding: boolean = false;
  private betHistory: BetHistory = new BetHistory();
  private get exchangeRate(): number {
    return this.$store.state.exchangeRate;
  }
  private showAlertBetSuccess: boolean = false;

  private hiddenModal() {
    this.betHistory = new BetHistory();
    this.$store.dispatch('setMatch', new MatchView());
    this.$store.dispatch('setModalMatch', false);
    this.showAlertBetSuccess = false;
  }

  private changeTeamWin(teamWin: number) {
    this.betHistory.teamWin = teamWin;
  }

  private back() {
    this.betHistory = new BetHistory();
  }

  private incrementBet() {
    if(this.betHistory.bet !== undefined) this.betHistory.bet++;
  }

  private decrementBet() {
    if(this.betHistory.bet !== undefined && this.betHistory.bet >= 2) this.betHistory.bet--;
  }

  private startIncrement() {
    if (this.isHolding) return;
    this.isHolding = true;
    // this.incrementBet();
    this.intervalid = window.setTimeout(() => {
      this.intervalid = setInterval(this.incrementBet, 100);
    }, 400);
  }

  private startDecrement() {
    if (this.isHolding) return;
    this.isHolding = true;
    // this.decrementBet();
    this.intervalid = window.setTimeout(() => {
      this.intervalid = setInterval(this.decrementBet, 100);
    }, 400);
  }

  private stopChange() {
    if (this.intervalid !== null) {
      clearTimeout(this.intervalid);
      clearInterval(this.intervalid);
      this.intervalid = 0;
    }
    this.isHolding = false;
  }

  private changeBet() {
    if(
      (this.betHistory.bet + '') === '' ||
      this.betHistory.bet === null ||
      this.betHistory.bet === undefined ||
      this.betHistory.bet < 1
    ) {
      this.betHistory.bet = 1;
    }
  }

  private formatSTP(value: number) {
    return value.toFixed(2);
  }

  private bet() {
    this.$store.dispatch('showLoading');

    this.betHistory.matchId = this.match.id;
    this.betHistory.betMoney = this.exchangeRate * this.betHistory.bet!;

    betHistoryService.createBetHistory(this.betHistory)
      .then((res: any) => {
        if (
          res.status === HttpStatus.OK &&
          res.data.code === ResponseCommon.CODE_SUCCESS
        ) {
          this.$store.dispatch('setBalanceUser', this.user.balance! - this.betHistory.betMoney!)
          this.showAlertBetSuccess = true;
          setTimeout(() => {
            this.hiddenModal();
          }, 2000);
        }
      })
      .finally(() => this.$store.dispatch('hiddenLoading'))
  }
}
