export class MatchView {
  public id?: number = undefined;
  public sportEN?: string = '';
  public sportJA?: string = '';
  public countryEN?: string = '';
  public countryJA?: string = '';
  public leagueEN?: string = '';
  public leagueJA?: string = '';
  public teamHomeEN?: string = '';
  public teamHomeJA?: string = '';
  public teamAwayEN?: string = '';
  public teamAwayJA?: string = '';
  public teamHomeScore?: number = undefined;
  public teamAwayScore?: number = undefined;
  public startTime?: Date = undefined;
  public endTime?: Date = undefined;
  public betEndTime?: Date = undefined;
  public state?: number = undefined;

  constructor(init?: Partial<MatchView>) {
    Object.assign(this, init)
  }
}
