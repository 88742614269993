






import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventBus} from '@/utils/EventBus';

@Component
export default class Message extends Vue {
  private isShowMessage: boolean = false;
  private hasError: boolean = false;
  private message: string = '';
  private timeOutHideError: any;
  private timeOutHideSuccess: any;
  @Prop()
  private events!: Vue;

  private created() {
    this.events.$on('show-error', (message) => {
      if (this.timeOutHideError) {
        clearTimeout(this.timeOutHideError);
      }
      this.showErrorMessage(message);
    });

    this.events.$on('show-success', (message) => {
      if (this.timeOutHideSuccess) {
        clearTimeout(this.timeOutHideSuccess);
      }
      this.showSuccessMessage(message);
    });

    EventBus.$on('show-success', (message) => {
      if (this.timeOutHideSuccess) {
        clearTimeout(this.timeOutHideSuccess);
      }
      this.showSuccessMessage(message);
    });

    this.events.$on('hide-message', () => {
      this.hideMessage();
    });
  }

  private showErrorMessage(message: string) {
    this.hasError = true;
    this.message = message;
    this.isShowMessage = true;
    this.timeOutHideError = setTimeout(() => {
      this.message = '';
      this.isShowMessage = false;
    }, 4000);
  }

  private showSuccessMessage(message: string) {
    this.hasError = false;
    this.message = message;
    this.isShowMessage = true;
    this.timeOutHideSuccess = setTimeout(() => {
      this.message = '';
      this.isShowMessage = false;
    }, 4000);
  }

  private hideMessage() {
    this.message = '';
    this.isShowMessage = false;
  }
}
