export class Match {
    public id?: number;
    public tourId?: number;
    public tourName?: string;
    public teamHomeId?: number;
    public teamAwayId?: number;
    public teamHomeName?: string;
    public teamAwayName?: string;
    public teamHomeLogo?: string;
    public teamAwayLogo?: string;
    public teamHomeScore?: number;
    public teamAwayScore?: number;
    public startTime?: Date;
    public endTime?: Date;
    public betEndTime?: Date;
    public state?: number;
    public dividendState?: number;

    constructor(init?: Partial<Match>) {
        Object.assign(this, init);
    }
}
