




import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/views/layout/index.vue';

@Component({
  components: {
    Layout
  },
})
export default class App extends Vue {
}
