import Vue from 'vue';

const URI_GET_COUNTRY = process.env.VUE_APP_API_URL + '/webapi/country/getCountries';

class CountryService extends Vue {
    public getCountries() {
        return this.axios.get(URI_GET_COUNTRY);
    }
}

export const countryService = new CountryService();
