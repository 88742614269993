import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import store from './store';
import TopPage from '@/views/TopPage.vue';
import RegisterPage from '@/views/user/RegisterPage.vue';
import VerifyPage from '@/views/user/VerifyPage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import LoginPage from '@/views/user/LoginPage.vue';
import ProfilePage from '@/views/user/ProfilePage.vue';
import GamePage from '@/views/GamePage.vue';
import MatchView from '@/views/MatchView.vue';
import ForecastGamePage from '@/views/ForecastGamePage.vue';
import GameResultPage from '@/views/GameResultPage.vue';
import AboutUsPage from '@/views/AboutUsPage.vue';
import TermsAndConditionsPage from '@/views/TermsAndConditionsPage.vue';
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue';
import AntiMoneyLaunderingPolicyPage from '@/views/AntiMoneyLaunderingPolicyPage.vue';
import ResponsibleGamingPolicyPage from '@/views/ResponsibleGamingPolicyPage.vue';
import TransactionMenuPage from '@/views/user/transaction/TransactionMenuPage.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history', // history
    base: process.env.BASE_URL,
    routes: [
        {
            path: '*',
            name: 'notfound',
            component: NotFoundPage
        },
        {
            path: '/',
            name: 'home',
            component: TopPage,
        },
        {
            path: '/user/register',
            name: 'user-register',
            component: RegisterPage,
        },
        {
            path: '/user/verify/:token',
            name: 'user-verify',
            component: VerifyPage,
        },
        {
            path: '/user/login',
            name: 'user-login',
            component: LoginPage,
        },
        {
            path: '/user/profile',
            name: 'user-profile',
            component: ProfilePage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/games',
            name: 'games',
            component: GamePage,
        },
        // {
        //   path: '/league/:tourId?',
        //   name: 'league',
        //   component: MatchView,
        // },
        {
            path: '/user/forecast-game',
            name: 'user-forecast-game',
            component: ForecastGamePage,
        },
        {
            path: '/user/game-result',
            name: 'user-game-result',
            component: GameResultPage,
        },
        {
            path: '/about-us',
            name: 'about-us',
            component: AboutUsPage,
        },
        {
            path: '/terms-and-conditions',
            name: 'terms-and-conditions',
            component: TermsAndConditionsPage,
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPolicyPage,
        },
        {
            path: '/anti-money-laundering-policy',
            name: 'anti-money-laundering-policy',
            component: AntiMoneyLaunderingPolicyPage,
        },
        {
            path: '/responsible-gaming-policy',
            name: 'responsible-gaming-policy',
            component: ResponsibleGamingPolicyPage,
        },
        {
            path: '/user/transaction-menu',
            name: 'user-transaction-menu',
            component: TransactionMenuPage,
        },
        // {
        //   path: '/match-before-end-bet-after-2-hours/:tourId?',
        //   name: 'matchesBeforeEndBetAfter2Hours',
        //   component: MatchesBeforeEndBetAfter2Hours,
        // },
        // {
        //   path: '/match-before-end-bet-before-2-hours/:tourId?',
        //   name: 'matchesBeforeEndBetBefore2Hours',
        //   component: MatchesBeforeEndBetBefore2Hours,
        // },
        // {
        //   path: '/match-ended-bet/:tourId?',
        //   name: 'matchesEndedBet',
        //   component: MatchesEndedBet,
        // },
        // {
        //   path: '/bet-match-user/:action?',
        //   name: 'betMatchesForCurrentUser',
        //   component: BetMatchesForCurrentUser,
        // },
        // {
        //   path: '/fcc-payment/:menu',
        //   name: 'fccPayment',
        //   component: FCCPayment,
        // },
        // {
        //   path: '/auth/register',
        //   name: 'userRegister',
        //   component: UserRegisterView,
        // },
        // {
        //   path: '/auth/register-confirm',
        //   name: 'userRegisterConfirm',
        //   component: ConfirmRegisterView,
        // },
        // {
        //   path: '/auth/active/:token',
        //   name: 'userActive',
        //   component: ActiveUserView,
        //   props: true,
        // },
        // {
        //   path: '/auth/login',
        //   name: 'userLogin',
        //   component: UserLoginView,
        //   props: (route) => ({
        //     firstLogin: route.query.first_login,
        //   }),
        // },
        // {
        //   path: '/auth/forget-password',
        //   name: 'forgetPassword',
        //   component: ForgetPasswordView,
        // },
        // {
        //   path: '/auth/reset-password/:token',
        //   name: 'resetPassword',
        //   component: ResetPasswordView,
        //   props: true,
        // },
        // {
        //   path: '/auth/profile',
        //   name: 'userProfile',
        //   component: UserProfileView,
        // },
        // {
        //   path: '/contact-us',
        //   name: 'contact',
        //   component: Contact,
        // },
        // {
        //   path: '/about-us',
        //   name: 'about-us',
        //   component: AboutUs,
        // },
        // {
        //   path: '/terms-of-use',
        //   name: 'terms-of-use',
        //   component: TermOfUse,
        // },
        // {
        //   path: '/privacy-policy',
        //   name: 'privacy-policy',
        //   component: PrivacyPolicy,
        // },
    ],
    // scrollBehavior(to, from, savedPosition) {
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             resolve({x: 0, y: 0});
    //         }, 500);
    //     });
    // },
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
        else{
            return { x: 0, y: 0 }
        }
    }
});

/**
 * Check Authenticate
 */
router.beforeEach((to, from, next) => {
    if (localStorage.getItem('jwttoken') !== null && (to.path === '/user/login' || to.path === '/user/register') ) {
        next({ path: '/' });
    } else {
        if (to.name === 'userRegisterConfirm' || to.name === 'userRegister' || to.name === 'userLogin'
            || to.name === 'forgetPassword' && from.name === 'userLogin') {
            if (localStorage.getItem('jwttoken') !== null) {
                next({
                    name: 'home',
                });
            } else {
                next();
            }
        }
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (localStorage.getItem('jwttoken') == null) {
                store.dispatch('setAuthenticated', false);
                delete axios.defaults.headers.common.Authorization;
                next({
                    path: '/user/login',
                    params: {nextUrl: to.fullPath},
                });
            } else {
                next();
            }
        } else if (to.matched.some((record) => record.meta.guest)) {
            if (localStorage.getItem('jwttoken') == null) {
                next();
            } else {
                next({name: 'userboard'});
            }
        } else {
            next();
        }
    }
});
// router.replace({ path: '/user/league'});
export default router;
