




















































































import { Component, Vue } from 'vue-property-decorator';
import { userService } from '@/services';
import ResponseCommon from '@/commons/ResponseCommon';
import { User } from '@/models';

@Component({
  components: {},
})
export default class App extends Vue {
  private langs = [
    {
      text: '日本語',
      code: 'ja',
      flag: require('@/assets/images/common/image-language/jp.png'),
    },
    {
      text: 'English',
      code: 'en',
      flag: require('@/assets/images/common/image-language/en.png'),
    },
  ];

  private isActiveMenu: boolean = false;
  public intervalid = 0;
  
  get user(): User {
    return this.$store.state.user;
  }

  private onChangeLanguage(event: any) {
    const lang = event.target.getAttribute('value');
    localStorage.setItem('Language', lang);
    this.axios.defaults.headers.common['Language'] = lang;
    this.$store.dispatch('setLang', lang);
    this.isActiveMenu = false;
    // this.$router.go(0);
  }

  public created() {
    this.getUserProfile();
    this.intervalid = setInterval(() => {
      this.getUserProfile();
    }, 10000);
  }

  private getUserProfile() {
    this.axios.prototype.loadHeaderInfo();
    if (this.axios.prototype.hasUserInfo()) {
      userService.getProfile().then((res: any) => {
        if (res.data.code === ResponseCommon.CODE_SUCCESS) {
          this.$store.dispatch('setUser', res.data.data);
        }
      });
    } else {
      this.axios.prototype.deleteUserInfo();
    }
  }

  private logout() {
    this.axios.prototype.deleteUserInfo();
    this.$router.push({ path: '/' });
  }

  private formatSTP(value: number) {
    return value.toFixed(2);
  }
}
