



































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { MatchView, User } from '@/models';
import Enums from '@/enums/enums';
import { dateUtils } from '@/utils/DateUtils';
import moment from 'moment';
import { settingService } from '@/services';
import HttpStatus from '@/commons/HttpStatus';
import ResponseCommon from '@/commons/ResponseCommon';
import MatchEnum from '@/enums/MatchEnum';

@Component({
  components: {
  },
})
export default class GameView extends Mixins(CommonMixin) {
  @Prop() private match!: MatchView;
  @Prop({ default: false }) private endBet!: boolean;
  private get DateUtils() {
    return dateUtils;
  }
  private get Enums() {
    return Enums;
  }
  private get MatchEnum() {
    return MatchEnum;
  }
  private get language() {
    return this.$store.state.storedLang;
  }
  get user(): User {
    return this.$store.state.user;
  }
  private remainingTime: string = '';
  public intervalid = 0;
  private isDisabledBet: boolean = false;

  private mounted() {
    this.calculateRemainingTime();
    this.intervalid = setInterval(this.calculateRemainingTime, 1000);
  }

  private calculateRemainingTime() {
    const now = moment();
    const duration = moment.duration(moment(this.match.betEndTime).diff(now));
    
    let hours = Math.floor(duration.asHours());
    let minutes = duration.minutes();
    let seconds = duration.seconds();

    if (hours <= 0) hours = 0;
    if (minutes <= 0) minutes = 0;
    if (seconds <= 0) seconds = 0;
    
    if(hours === 0 && minutes === 0 && seconds === 0) {
      this.isDisabledBet = true;
    } else {
      this.isDisabledBet = false;
    }

    let hoursStr = String(hours).padStart(2, '0');
    let minutesStr = String(minutes).padStart(2, '0');
    let secondsStr = String(seconds).padStart(2, '0');

    this.remainingTime = `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  private async getExchangeRate() {
    await settingService.getExchangeRate()
      .then((res: any) => {
        if (
          res.status === HttpStatus.OK &&
          res.data.code === ResponseCommon.CODE_SUCCESS
        ) {
          this.$store.dispatch('setExchangeRate', isNaN(res.data.data) ? -1 : Number(res.data.data));
        }
      })
  }

  private async showModalMatch() {
    this.$store.dispatch('setMatch', this.match);
    await this.getExchangeRate();
    this.$store.dispatch('setModalMatch', true);
    if (!this.user.id) {
      this.$router.push({ name: 'user-login' });
    }
  }
}
