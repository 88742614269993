


























import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { BetHistoryView, DataTableRequest } from '@/models';
import { betHistoryService } from '@/services';
import HttpStatus from '@/commons/HttpStatus';
import ResponseCommon from '@/commons/ResponseCommon';
import GameResultView from '@/components/game/GameResultView.vue';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
    GameResultView
  },
})
export default class GameResultPage extends Mixins(CommonMixin) {
  private dateUtils = dateUtils;
  private LIMIT: number = 10;
  private resultGameRequest: DataTableRequest = new DataTableRequest({
    currentPage: 0,
    perPage: this.LIMIT,
    filter: '',
    sortBy: 'm.startTime',
    sortDesc: true
  });
  private listResultGame: BetHistoryView[] = [];
  private totalRows: number = -1;

  private async created() {
    await this.getListForecastGame();
  }

  private seeMore() {
    if(this.listResultGame.length < this.totalRows) {
      this.getListForecastGame();
    }
  }

  private async getListForecastGame() {
    this.$store.dispatch('showLoading');

    this.resultGameRequest.currentPage += 1;
    await betHistoryService.getAllResult(this.resultGameRequest)
      .then((res: any) => {
        if (
          res.status === HttpStatus.OK &&
          res.data.code === ResponseCommon.CODE_SUCCESS
        ) {
          this.listResultGame.push(...res.data.items);
          this.totalRows = res.data.totalRows;
        }
      }).finally(() => this.$store.dispatch('hiddenLoading'));
  }
}
