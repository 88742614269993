import Vue from 'vue';

const URI_GET_EXCHANGE_RATE = process.env.VUE_APP_API_URL + '/webapi/setting/get-exchange-rate';

class SettingService extends Vue {

  public getExchangeRate() {
    return this.axios.get(URI_GET_EXCHANGE_RATE);
  }
}

export const settingService = new SettingService();
