import Vue from 'vue';

const URI_GET_TOUR = process.env.VUE_APP_API_URL + '/webapi/tournament/getTournament';
const URI_GET_TOUR_BY_COUNTRY_ID = process.env.VUE_APP_API_URL + '/webapi/tournament/getTournamentByCountryId';
const URI_GET_TOUR_GROUP_BY_COUNTRY = process.env.VUE_APP_API_URL + '/webapi/tournament/getTournamentsGroupByCountry';

class TournamentService extends Vue {

    public get() {
        return this.axios.get(URI_GET_TOUR);
    }

    public getTournamentByCountryId(countryId: number) {
        return this.axios.get(URI_GET_TOUR_BY_COUNTRY_ID + '/' + countryId);
    }

    public getTournamentsGroupByCountry() {
        return this.axios.get(URI_GET_TOUR_GROUP_BY_COUNTRY);
    }
}

export const tournamentService = new TournamentService();

