import { DataTableRequest } from '@/models';
import Vue from 'vue';

const GET_MATCHES_BEFORE_END_BET_AFTER_2_HOURS = process.env.VUE_APP_API_URL + '/webapi/match/getMatchesBeforeEndBetAfter2Hours';
const GET_MATCHES_BEFORE_END_BET_BEFORE_2_HOURS = process.env.VUE_APP_API_URL + '/webapi/match/getMatchesBeforeEndBetBefore2Hours';
const GET_MATCHES_ENDED_BET = process.env.VUE_APP_API_URL + '/webapi/match/getMatchesEndedBet';
const GET_RESULTED_MATCH_BY_USER = process.env.VUE_APP_API_URL + '/webapi/match/getResultedMatchByUser';
const GET_RESULTED_MATCHES_BY_USER = process.env.VUE_APP_API_URL + '/webapi/match/getResultedMatchesByUser';
const GET_BETTED_MATCHES_BY_USER = process.env.VUE_APP_API_URL + '/webapi/match/getBettedMatchesByUser';
const GET_LIST_MATCH_BEFORE_END_BET_AFTER_2_HOURS = process.env.VUE_APP_API_URL + '/webapi/match/get-list-match-before-end-bet-after-2-hours';
const GET_LIST_MATCH_BEFORE_END_BET_BEFORE_2_HOURS = process.env.VUE_APP_API_URL + '/webapi/match/get-list-match-before-end-bet-before-2-hours';
const GET_LIST_MATCH_END_BET = process.env.VUE_APP_API_URL + '/webapi/match/get-list-match-end-bet';

class MatchService extends Vue {

    public getMatchesBeforeEndBetAfter2Hours(data: any) {
        return this.axios.post(GET_MATCHES_BEFORE_END_BET_AFTER_2_HOURS, data);
    }

    public getMatchesBeforeEndBetBefore2Hours(data: any) {
        return this.axios.post(GET_MATCHES_BEFORE_END_BET_BEFORE_2_HOURS, data);
    }

    public getMatchesEndedBet(data: any) {
        return this.axios.post(GET_MATCHES_ENDED_BET, data);
    }

    public getResultedMatchByUser(matchId?: number) {
        return this.axios.post(GET_RESULTED_MATCH_BY_USER, {matchId});
    }

    public getResultedMatchesByUser(data: any) {
        return this.axios.post(GET_RESULTED_MATCHES_BY_USER, data);
    }

    public getBettedMatchesByUser(data: any) {
        return this.axios.post(GET_BETTED_MATCHES_BY_USER, data);
    }

    public getListMatchBeforeEndBetAfter2Hours(params: DataTableRequest) {
        return this.axios.get(GET_LIST_MATCH_BEFORE_END_BET_AFTER_2_HOURS, { params });
    }

    public getListMatchBeforeEndBetBefore2Hours(params: DataTableRequest) {
        return this.axios.get(GET_LIST_MATCH_BEFORE_END_BET_BEFORE_2_HOURS, { params });
    }

    public getListMatchEndBet(params: DataTableRequest) {
        return this.axios.get(GET_LIST_MATCH_END_BET, { params });
    }
}

export const matchService = new MatchService();
