import Vue from 'vue';
import Vuex from 'vuex';
import mainApp from './main';
import {Match, MatchView} from '@/models/';
import axios from 'axios';

// @ts-ignore
import createPersist from 'vuex-localstorage';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersist({
        namespace: 'fcc.root',
        initialState: {},
    })],
    state: {
        storedLang: 'ja',
        user: {
            balance: 0
        },
        locale: 'ja',
        selectingMatch: Match,
        showBetWDModal: false,
        showBetWinModal: false,
        showBetDrawModal: false,
        showResultModal: false,
        isShowLoading: false,
        showModalMatch: false,
        match: new MatchView(),
        exchangeRate: -1
    },
    mutations: {
        SET_LANG(state, storedLang) {
            mainApp.$i18n.locale = storedLang;
            state.storedLang = storedLang;
        },
        SET_USER(state, payload) {
            state.user = payload;
        },
        SET_LOCALE(state, locale) {
            state.locale = locale;
        },
        SET_SELECTING_MATCH(state, payload) {
            state.selectingMatch = payload;
        },
        SHOW_BET_WD_MODAL(state, payload) {
            state.showBetWDModal = payload;
        },
        SHOW_BET_WIN_MODAL(state, payload) {
            state.showBetWinModal = payload;
        },
        SHOW_BET_DRAW_MODAL(state, payload) {
            state.showBetDrawModal = payload;
        },
        SHOW_RESULT_MODAL(state, payload) {
            state.showResultModal = payload;
        },
        CHANGE_LOADING(state, payload) {
            state.isShowLoading = payload;
        },
        SET_SHOW_MODAL_MATCH(state, payload) {
            state.showModalMatch = payload;
        },
        SET_MATCH(state, payload) {
            state.match = payload;
        },
        SET_EXCHANGE_RATE(state, payload) {
            state.exchangeRate = payload;
        },
        SET_BALANCE_USER(state, payload) {
            state.user.balance = payload;
        }
    },
    actions: {
        setLang({commit}, storedLang) {
            commit('SET_LANG', storedLang);
        },
        setUser({commit}, user) {
            commit('SET_USER', user);
        },
        setLocale({commit}, locale) {
            commit('SET_LOCALE', locale);
        },
        setSelectingMatch({commit}, payload) {
            commit('SET_SELECTING_MATCH', payload);
        },
        showBetWDModal({commit}, payload) {
            commit('SHOW_BET_WD_MODAL', payload);
        },
        showBetWinModal({commit}, payload) {
            commit('SHOW_BET_WIN_MODAL', payload);
        },
        showBetDrawModal({commit}, payload) {
            commit('SHOW_BET_DRAW_MODAL', payload);
        },
        showResultModal({commit}, payload) {
            commit('SHOW_RESULT_MODAL', payload);
        },
        hiddenLoading({commit}) {
            commit('CHANGE_LOADING', false);
        },
        showLoading({commit}) {
            commit('CHANGE_LOADING', true);
        },
        setModalMatch({commit}, payload) {
            commit('SET_SHOW_MODAL_MATCH', payload);
        },
        setMatch({commit}, payload) {
            commit('SET_MATCH', payload);
        },
        setExchangeRate({commit}, payload) {
            commit('SET_EXCHANGE_RATE', payload);
        },
        setBalanceUser({commit}, payload) {
            commit('SET_BALANCE_USER', payload);
        }
    },
});
