export class BetHistory {
  public id?: number = undefined;
  public userId?: number = undefined;
  public matchId?: number = undefined;
  public betTournamentId?: number = undefined;
  public bet?: number = 1;
  public betMoney?: number = undefined;
  public teamWin?: number = undefined;
  public isWin?: number = undefined;

  constructor(init?: Partial<BetHistory>) {
    Object.assign(this, init);
  }
}
