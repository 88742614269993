






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Loading extends Vue {
  get isShowLoading(): boolean {
    return this.$store.state.isShowLoading;
  }
}
