

































































































































































































































































































import { Vue, Component, Mixins } from 'vue-property-decorator';

import {userService} from '@/services';
import { User } from '@/models';
import {validationUtils} from '@/validation/ValidationUtils';
import HttpStatus from '@/commons/HttpStatus';
import {InputError} from '@/validation/InputError';
import CommonMixin from '@/components/CommonMixin';
import TimeZoneList from '@/commons/TimeZoneList';
import Cookies from 'js-cookie';
import $ from 'jquery';
import 'daterangepicker';
import moment from 'moment';

@Component({
  components: {
  },
})
export default class RegisterPage extends Mixins(CommonMixin) {
  private validationUtils = validationUtils;
  private errors: InputError[] = [];
  private isAlertError = false;
  private isAlertSuccess = false;
  private $bvModal: any;

  private user: User = new User();

  private mounted() {
    ($(this.$refs.birthday) as any).daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
      locale: {
          format: 'YYYY-MM-DD',
          cancelLabel: 'Clear'
      },
      minYear: 1901,
      maxYear: parseInt(moment().format('YYYY'),10)
    }, (start: any) => {
      this.user.birthday = start.format('YYYY-MM-DD');
    });

    $(this.$refs.daterangepicker).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD'));
    });
  }

  private created() {
    if(!(this.$route.query.ref === null || this.$route.query.ref === undefined)) {
      Cookies.set("register-affiliate-id", this.$route.query.ref);
    }
    this.errors = validationUtils.initErrors(this.user);
  }

  private register() {
    validationUtils.clearError(this.errors);
    this.isAlertError = false;
    this.isAlertSuccess = false;
    this.$store.dispatch('showLoading');
    if(
      !this.user.isAge &&
      !this.user.isPolicy
    ) {
      this.$store.dispatch('hiddenLoading');
      this.$bvModal.show('bv-modal-example');
      return;
    }
    userService.register(this.user).then((res: any) => {
      if(res.status === HttpStatus.OK) {
        this.isAlertSuccess = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    })
    .catch((error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.violations
      ) {
        validationUtils.loadFieldErrors(
          error.response.data.violations,
          this.errors,
        );
        if(
          this.errors.filter((e: any) => e.isError === false) &&
          this.errors.filter((e: any) => e.isError === false)[0].field
        ) {
          (this.$refs[this.errors.filter((e: any) => e.isError === false)[0].field as string] as any).focus();
        }
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        this.isAlertError = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }).finally(() => {
      this.$store.dispatch('hiddenLoading');
    });
  }
}
