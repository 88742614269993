import {Vue, Component} from 'vue-property-decorator';
import {EventBus} from '@/utils/EventBus';
import Message from '@/components/Message.vue';
import Loading from '@/components/Loading.vue';

@Component({
    components: {
        'message': Message,
        'loading': Loading,
    },
})
export default class CommonMixin extends Vue {
    public messageEvent: Vue = new Vue();

    public switchLayoutHaveSidebar() {
        // this event will be handled in the file App.vue
        // to change layout (have left side bar)
        EventBus.$emit('HaveSidebar');
    }

    public switchLayoutNoSidebar() {
        // this event will be handled in the file App.vue
        // to change layout (do not have left side bar)
        EventBus.$emit('NoSidebar');
    }

    public showSuccessMessage(messageName: string, messageEvent?: Vue) {
        const message = this.$t(messageName).toString();
        const eventer = (messageEvent !== undefined) ? messageEvent : this.messageEvent;
        eventer.$emit('show-success', message);
    }

    public showErrorMessage(messageName: string, messageEvent?: Vue) {
        const message = this.$t(messageName).toString();
        const eventer = (messageEvent !== undefined) ? messageEvent : this.messageEvent;
        eventer.$emit('show-error', message);
    }

    public showSuccessTextMessage(message: string, messageEvent?: Vue) {
        // const eventer = (messageEvent != undefined) ? messageEvent : this.messageEvent;
        EventBus.$emit('show-success', message);
    }

    public showErrorTextMessage(message: string, messageEvent?: Vue) {
        const eventer = (messageEvent !== undefined) ? messageEvent : this.messageEvent;
        eventer.$emit('show-error', message);
    }

    public hideMessage(messageEvent?: Vue) {
        const eventer = (messageEvent !== undefined) ? messageEvent : this.messageEvent;
        eventer.$emit('show-error');
    }
}
