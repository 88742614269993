class BetHistoryEnum {
  public static TeamWin = {
    TEAM_HOME_WIN: 1,
    TEAM_AWAY_WIN: 2,
    DRAW: 0
  };

  public static IsWin = {
    WIN: 1,
    LOSE: 0
  };
}

export default BetHistoryEnum;