
































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';

@Component({
  components: {
  },
})
export default class PrivacyPolicyPage extends Mixins(CommonMixin) {
  private get language() {
    return this.$store.state.storedLang
  }

  @Watch('language')
  private onChangeLang(val: string, oldVal: string) {
    this.$nextTick(() => {
      const linkContactUs = document.getElementsByClassName("linkContactUs");
      if (linkContactUs) {
        for (var i = 0; i < linkContactUs.length; i++) {
          linkContactUs[i].addEventListener('click', this.toPageContactUs);
        }
      }
    });
  }

  private toPageContactUs(event: Event) {
    event.preventDefault();
    this.$router.push({ path: '/contact-us' });
  }

  private created() {
    this.$nextTick(() => {
      const linkContactUs = document.getElementsByClassName("linkContactUs");
      if (linkContactUs) {
        for (var i = 0; i < linkContactUs.length; i++) {
          linkContactUs[i].addEventListener('click', this.toPageContactUs);
        }
      }
    });
  }
}
