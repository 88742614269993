import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enMessage from './en.json';
import jaMessage from './ja.json';
import store from '../store';

Vue.use(VueI18n);

const messages = {
    en: enMessage,
    ja: jaMessage,
};

const i18n = new VueI18n({
    locale: store.state.storedLang, // set locale
    messages,
    fallbackLocale: 'ja',
    silentTranslationWarn: true,
});

export default i18n;
