import { BetHistory, DataTableRequest } from '@/models';
import Vue from 'vue';

const BET = process.env.VUE_APP_API_URL + '/webapi/bethistory/bet';
const URI_CREATE_BET_HISTORY = process.env.VUE_APP_API_URL + '/webapi/bet-history';
const URI_GET_ALL_FORECAST_GAME = process.env.VUE_APP_API_URL + '/webapi/bet-history/get-all-forecast-game';
const URI_GET_ALL_RESULT = process.env.VUE_APP_API_URL + '/webapi/bet-history/get-all-result';

class BetHistoryService extends Vue {

  public bet(matchId: number, betMoney: number, teamHomeScore: number, teamAwayScore: number) {
    return this.axios.post(BET, {matchId, betMoney, teamHomeScore, teamAwayScore});
  }

  public createBetHistory(data: BetHistory) {
    return this.axios.post(URI_CREATE_BET_HISTORY, data);
  }

  public getAllForecastGame(params: DataTableRequest) {
    return this.axios.get(URI_GET_ALL_FORECAST_GAME, { params });
  }

  public getAllResult(params: DataTableRequest) {
    return this.axios.get(URI_GET_ALL_RESULT, { params });
  }
}

export const betHistoryService = new BetHistoryService();
