class MatchEnum {
  public static State = {
    NOT_STARTED: 0,
    ENDED_BET: 1,
    ORGANIZING: 2,
    ENDED: 3,
    POSTPONED: 4,
    CANCELED: 5
  };

  public static TeamWin = {
    TEAM_HOME_WIN: 1,
    TEAM_AWAY_WIN: 2,
    DRAW: 0
  };
}

export default MatchEnum;