



















































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { BetHistoryView, User } from '@/models';
import Enums from '@/enums/enums';
import { dateUtils } from '@/utils/DateUtils';
import BetHistoryEnum from '@/enums/BetHistoryEnum';
import MatchEnum from '@/enums/MatchEnum';

@Component({
  components: {
  },
})
export default class GameResultView extends Mixins(CommonMixin) {
  @Prop() private betHistory!: BetHistoryView;
  private get DateUtils() {
    return dateUtils;
  }
  private get Enums() {
    return Enums;
  }
  private get language() {
    return this.$store.state.storedLang;
  }
  get user(): User {
    return this.$store.state.user;
  }
  private get TeamWin() {
    return BetHistoryEnum.TeamWin;
  }
  private get IsWin() {
    return BetHistoryEnum.IsWin;
  }
  private get MatchState() {
    return MatchEnum.State;
  }
}
