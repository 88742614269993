



































































































import { Vue, Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { DataTableRequest, MatchView } from '@/models';
import { matchService } from '@/services';
import HttpStatus from '@/commons/HttpStatus';
import ResponseCommon from '@/commons/ResponseCommon';
import GameView from '@/components/game/GameView.vue';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
    GameView
  },
})
export default class GamePage extends Mixins(CommonMixin) {
  private dateUtils = dateUtils;
  private LIMIT: number = 6;
  private after2HoursRequest: DataTableRequest = new DataTableRequest({
    currentPage: 0,
    perPage: this.LIMIT,
    filter: '',
    sortBy: 'startTime',
    sortDesc: true
  });
  private before2HoursRequest: DataTableRequest = new DataTableRequest({
    currentPage: 0,
    perPage: this.LIMIT,
    filter: '',
    sortBy: 'startTime',
    sortDesc: false
  });
  private endBetRequest: DataTableRequest = new DataTableRequest({
    currentPage: 0,
    perPage: this.LIMIT,
    filter: '',
    sortBy: 'startTime',
    sortDesc: true
  });
  private searchTeam: string = '';
  private listAfter2Hours: MatchView[] = [];
  private totalRowsAfter2Hours: number = -1;
  private listBefore2Hours: MatchView[] = [];
  private totalRowsBefore2Hours: number = -1;
  private listEndBet: MatchView[] = [];
  private totalRowsEndBet: number = -1;

  private async created() {
    await this.init();
  }

  private async init() {
    this.$store.dispatch('showLoading');

    await this.getListMatchBeforeEndBetAfter2Hours(true);
    await this.getListMatchBeforeEndBetBefore2Hours(true);
    await this.getListMatchEndBet(true);
    
    this.$store.dispatch('hiddenLoading');
  }

  private async changeSearchTeam() {
    this.endBetRequest.filter = this.searchTeam;
    this.endBetRequest.currentPage = 0;
    this.after2HoursRequest.filter = this.searchTeam;
    this.after2HoursRequest.currentPage = 0;
    this.before2HoursRequest.filter = this.searchTeam;
    this.before2HoursRequest.currentPage = 0;
    await this.getListMatchBeforeEndBetAfter2Hours(false);
    await this.getListMatchBeforeEndBetBefore2Hours(false);
    await this.getListMatchEndBet(false);
  }

  private async getListMatchEndBet(push: boolean) {
    this.endBetRequest.currentPage += 1;
    await matchService.getListMatchEndBet(this.endBetRequest)
      .then((res: any) => {
        if (
          res.status === HttpStatus.OK &&
          res.data.code === ResponseCommon.CODE_SUCCESS
        ) {
          this.totalRowsEndBet = res.data.totalRows;
          if (push) {
            this.listEndBet.push(...res.data.items);
          } else {
            this.listEndBet = res.data.items;
          }
        }
      })
  }

  private async getListMatchBeforeEndBetAfter2Hours(push: boolean) {
    this.after2HoursRequest.currentPage += 1;
    await matchService.getListMatchBeforeEndBetAfter2Hours(this.after2HoursRequest)
      .then((res: any) => {
        if (
          res.status === HttpStatus.OK &&
          res.data.code === ResponseCommon.CODE_SUCCESS
        ) {
          this.totalRowsAfter2Hours = res.data.totalRows;
          if (push) {
            this.listAfter2Hours.push(...res.data.items);
          } else {
            this.listAfter2Hours = res.data.items;
          }
        }
      })
  }

  private async getListMatchBeforeEndBetBefore2Hours(push: boolean) {
    this.before2HoursRequest.currentPage += 1;
    await matchService.getListMatchBeforeEndBetBefore2Hours(this.before2HoursRequest)
      .then((res: any) => {
        if (
          res.status === HttpStatus.OK &&
          res.data.code === ResponseCommon.CODE_SUCCESS
        ) {
          this.totalRowsBefore2Hours = res.data.totalRows;
          if (push) {
            this.listBefore2Hours.push(...res.data.items);
          } else {
            this.listBefore2Hours = res.data.items;
          }
        }
      })
  }
}
