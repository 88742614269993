















import {Component, Vue, Mixins} from 'vue-property-decorator';
import Header from './Header.vue';
import Footer from './Footer.vue';
import CommonMixin from '@/components/CommonMixin';
import ModalMatch from '@/components/game/ModalMatch.vue';
import { MatchView } from '@/models';

@Component({
  components: {
    Header,
    Footer,
    ModalMatch
  },
})
export default class App extends Mixins(CommonMixin) {

  private created() {
    window.addEventListener('beforeunload', this.handleUnload);
  }

  private handleUnload() {
    this.$store.dispatch('setMatch', new MatchView());
    this.$store.dispatch('setModalMatch', false);
  }
}
