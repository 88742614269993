class ResponseCommon {
    public static readonly CODE_SUCCESS = 0;
    public static readonly CODE_PERMISSION = 1;
    public static readonly CODE_NOT_FOUND = 2;
    public static readonly CODE_ALREADY_EXIST = 3;
    public static readonly CODE_SEND_TIME_INVALID = 4;
    public static readonly CODE_SEND_TEXT_INVALID = 5;
    public static readonly CODE_EMAIL_INVALID = 6;
    public static readonly CODE_PHONENUMBER_INVALID = 7;
    public static readonly CODE_AGE_INVALID = 8;
    public static readonly CODE_FIELD_REQUIED = 9;
    public static readonly CODE_URL_EXIST = 10;
    public static readonly CODE_URL_INVALID = 11;
    public static readonly CODE_PASSWORD_REQUIED = 12;
    public static readonly CODE_LOGIN_REQUIED = 13;
    public static readonly CODE_INTERNAL_ERROR = 9999;
    public static readonly CODE_PASSWORD_INVALID = 14;
    public static readonly CODE_OTP_INCORRECT = 15;
    public static readonly CODE_PAYMENT_EXITS = 17;
}

export default ResponseCommon;
