import Vue from 'vue';

const URI_DEPOSITE_FCC = process.env.VUE_APP_API_URL + '/webapi/fccpayment/depositFCC';
const URI_BUY_FCC_WITH_BTC = process.env.VUE_APP_API_URL + '/webapi/fccpayment/buyFCCWithBTC';
const URI_BUY_FCC_WITH_ETH = process.env.VUE_APP_API_URL + '/webapi/fccpayment/buyFCCWithETH';

class FccPaymentService extends Vue {

    public depositFCC(amountFCC: number) {
        return this.axios.get(URI_DEPOSITE_FCC + '/' + amountFCC);
    }

    public buyFCCWithBTC(amountFCC: number) {
        return this.axios.get(URI_BUY_FCC_WITH_BTC + '/' + amountFCC);
    }

    public buyFCCWithETH(amountFCC: number) {
        return this.axios.get(URI_BUY_FCC_WITH_ETH + '/' + amountFCC);
    }
}

export const fccPaymentService = new FccPaymentService();
